import React, { Component } from 'react';
import './googlemap.scss';

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.createMarker = this.createMarker.bind(this);
  }
  map = null;
  service = null;

  componentDidMount() {
    this.initMap();
  }

  initMap() {
    const request = {
      query: 'Orange Bees',
      fields: ['name', 'geometry', 'formatted_address']
    };

    this.map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 17,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU
      },
      styles: [
        { elementType: 'geometry', stylers: [{ color: '#333234' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#29282A' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#c2c1c3' }] },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#FEB114' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#CDD9BF' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#748E54' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#414042' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#1f1e1f' }],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#adacaf' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{ color: '#666468' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#333234' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#FEB114' }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#2f3948' }],
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#FEB114' }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#5F81B0' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#2F4360' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#141D29' }],
        },
      ],
    });

    this.service = new window.google.maps.places.PlacesService(this.map);
    this.service.findPlaceFromQuery(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
        for (let i = 0; i < results.length; i++) {
          this.createMarker(results[i]);
        }
        this.map.setCenter(results[0].geometry.location);
      }
    });
  }

  createMarker(place) {
    if (!place.geometry || !place.geometry.location) return;

    const marker = new window.google.maps.Marker({
      map: this.map,
      position: place.geometry.location,
    });

    const infoTitle = place.name;
    const infoAddress = place.formatted_address;

    const infoWindow = new window.google.maps.InfoWindow();
    const contentString =
      `<div class="info-window">
        <div class="info-title"> ${infoTitle} </div>
        <div class="info-address"> ${infoAddress} </div>
        <div class="info-link">
          <a href="https://www.google.com/maps/search/?api=1&query=orange+bees" target="_blank">View on Google Maps</a>
        </div>
      </div>
    `;

    infoWindow.setContent(contentString);

    marker.addListener('click', () => {
      infoWindow.open(this.map, marker);
    });
  }
  render() {
    return (
      <>
        <div id="map" className="map-container"></div>
      </>
    );
  }
}

export default MapContainer;

