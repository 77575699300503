import React from "react"
import Form from "./form.js"
import "./hero.scss"
import { getStaticContentByIdentifier } from "../../services/misc.service";

export default (props) => {
  return (
    <section className="contact-hero bg-gradient">
      <div className="contact-container-flex">
        <div className="row">
          <div className="contact-hero-main-heading text-center">{getStaticContentByIdentifier('contact.hero.title', props.staticContent)}</div>
        </div>
        <div className="row">
          <div className="contact-hero-sub-heading text-center">{getStaticContentByIdentifier('contact.hero.content', props.staticContent)}</div>
        </div>
        <Form staticContent={props.staticContent}></Form>
      </div>
    </section>
  )
}
/*
 <li className="text-small"><a href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3274.447749017627!2d-82.4127517!3d34.8449752!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8858304aa13dbb47%3A0x26294b6908d7ad88!2s119+N+Markley+St%2C+Greenville%2C+SC+29601!5e0!3m2!1sen!2sus!4v1559312920291!5m2!1sen!2sus">119 N Markley St<br/>Greenville SC, 29601</a></li>
 */