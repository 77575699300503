import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import Hero from "../components/contact/hero"
import MapContainer from "../components/contact/googlemap";
import Seo from "../components/seo2"
import { getSEOByIdentifier } from "../services/seo.service";

const ContactPage = (props) => {
  const SEOInfo = props.pageContext.SEOInfo;
  const staticContent = props.pageContext.staticContent;

  return (
    <Layout>
      <Seo SEOInfo={getSEOByIdentifier('contact', SEOInfo)}/>
      <Header />
      <main id="maincontent">
        <Hero staticContent={staticContent}/>
        <MapContainer></MapContainer>
      </main>
      <Footer />
    </Layout>
  )
}

export default ContactPage;